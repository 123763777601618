
// ベースカラー
$color-bg: #fff;
// テキストカラー
$color-font: #030000;
// メインカラー
$color-main: #f49d15;
// サブカラー（ベースカラー）
$color-sub: #f7eed2;

$color-key: #1e3f20;

// アクセントカラー
$color-accent: #c30;
// ラインカラー
$color-line: #666;

$root-font-size: 62.5%;
$base-font-size: 1.6rem;
$base-letter-spacing: .05em;

$font-sans: -apple-system, BlinkMacSystemFont,"Segoe UI","Hiragino Sans", "Hiragino Kaku Gothic ProN","Noto Sans Japanese","Yu Gothic Medium",Meiryo,sans-serif !default;
$font-serif: "Hiragino Mincho ProN", "Noto Serif Japanese", "Yu Mincho", YuMincho, serif !default;
$font-mono: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", Meiryo, monospace, serif !default;
// ベースフォント
// $base-font: "メイリオ", "Meiryo", verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif;
// $base-font: "Noto Sans JP", "Noto Sans CJK JP", "Noto Sans Japanese", Helvetica-Light, 'Helvetica Light', Helvetica, YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;
$base-font: 'Noto Sans JP', sans-serif;;
$font-b: 'Kiwi Maru', serif;

// ページ全体の横幅
$inner-width: 1080px;

$mq-pc-width: $inner-width;
$mq-tab-width: 740px;
$padding-tab: 16px;
$padding-sp: 8px;

$breakpoints: (
	pc : "screen and (min-width:#{$mq-pc-width})",
	tab: "screen and (max-width:#{$mq-pc-width - 1}) and (min-width: #{$mq-tab-width})",
	sp : "screen and (max-width:#{$mq-tab-width - 1})",
	xl : "screen and (min-width:1280px)",
	lg : "screen and (max-width:1279px) and (min-width: 960px)",
	md : "screen and (max-width:959px) and (min-width:768px)",
	sm : "screen and (max-width:767px) and (min-width:520px)",
	xs : "screen and (max-width: 519px)",
) !default;;


$space-base-size: (
	pc: 8px,
	tab: 6px,
	sp : 4px
);
$space-size-ratio: (
	xxl: 20,
	xl:  15,
	lg:  10,
	md:  6,
	sm:  3,
	xs:  1,
	xxs: .5
);

