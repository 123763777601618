@use '../global' as *;

.d{
	&-b{
		display: block !important;
	}
	&-ib{
		display: inline-block !important;
	}
	&-i{
		display: inline !important;
	}
	&-f{
		display: flex !important;
	}
	&-if{
		display: inline-flex !important;
	}
}

.hidden{
	&-pc{
		display: none !important;
		@include mq(tab){
			display: inherit !important;
		}
		@include mq(sp){
			display: inherit !important;
		}
	}
	&-tab{
		// display: none;
		@include mq(tab){
			display: none !important;
		}
	}
	&-sp{
		@include mq(sp){
			display: none !important;
		}
	}
}

.visible{
	&-pc{
		@include mq(tab){
			display: none !important;
		}
		@include mq(sp){
			display: none !important;
		}
	}
	&-tab{
		@include mq(pc){
			display: none !important;
		}
		@include mq(sp){
			display: none !important;
		}
	}
	&-sp{
		@include mq(pc){
			display: none !important;
		}
		@include mq(tab){
			display: none !important;
		}
	}
}
.bg-w{
	background-color: #fff;
}

.bg-y{
	border-image-source: linear-gradient(0, $color-sub,$color-sub);
	border-image-slice: 0 fill;
	border-image-outset: 0 100vw;
}
.frame-w{
	padding: 3em;
	border: 1px solid $color-line;
	background-color: #fff;
	@include mq("tab"){
		padding: 2em;
	}
	@include mq("sp"){
		padding: 1em;
	}
}

.arrow-down{
	width: fit-content;
	margin: 0 auto;
	border: 18px solid transparent;
	border-top-color: $color-key;
	border-bottom-width: 0;
}