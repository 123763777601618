@use '../global' as *;

// color
.fc{
	&-font{
		color: $color_font !important;
	}
	&-main{
		color: $color_main !important;
	}
	&-sub{
		color: $color_sub !important;
	}
	&-key{
		color: $color_key !important;
	}
	&-accent{
		color: $color_accent !important;
	}
	&-attention{
		color: #d00 !important;
	}
}

.ff{
	&-b{
		font-family: $font-b;
	}
}
// font-size
// $start: 10;
// $end: 32;
// $unit: rem;

$font_size: (
  xxl: 2.8,
  xl: 2.4,
  lg: 2.2,
  md: 1.8,
  sm: 1.3,
  xs: 1.2,
  xxs: 1
);
$font_size_tab: (
  xxl: 2.4,
  xl: 2.2,
  lg: 1.8,
  md: 1.5,
  sm: 1.2,
  xs: 1.1,
  xxs: 1
);
$font_size_sp: (
  xxl: 2.1,
  xl: 2,
  lg: 1.8,
  md: 1.4,
  sm: 1.2,
  xs: 1,
  xxs: 1
);
.fz{
	@each $name, $size in $font_size {
		&-#{$name}{
			font-size: #{$size}rem;
		}
	}
	@include mq(tab) {
		@each $name, $size in $font_size_tab {
			&-#{$name}{
				font-size: #{$size}rem;
			}
		}
	}
	@include mq(sp) {
		@each $name, $size in $font_size_sp {
			&-#{$name}{
				font-size: #{$size}rem;
			}
		}
	}
}

// font-weight
.fw{
	&-b{
		font-weight: bolder;
	}
	&-100{
		font-weight: 100;
	}
	&-200{
		font-weight: 200;
	}
	&-300{
		font-weight: 300;
	}
	&-400{
		font-weight: 400;
	}
	&-500{
		font-weight: 500;
	}
	&-600{
		font-weight: 600;
	}
	&-700{
		font-weight: 700;
	}
	&-900{
		font-weight: 900;
	}
}

// font-family
.ff{
	&-mincho{
		font-family: $font-serif;
	}
}

// line-height
.lh{
	&-xl{ line-height: 2.4 !important;}
	&-lg{ line-height: 1.9 !important;}
	&-md{ line-height: 1.6 !important;}
	&-sm{ line-height: 1.2 !important;}
	&-xs{ line-height: 1 !important;}
}

// text-align
.ta{
	&-l{ text-align: left !important;}
	&-c{ text-align: center !important;}
	&-r{ text-align: right !important;}
}

a[href^="tel:"]{
	pointer-events: none;
	@include mq(sp) {
		pointer-events: all;
	}
}