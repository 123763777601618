@use '../global' as *;


html {
	overflow-y: scroll;
	font-size: $root-font-size;
}
body {
	color: $color-font;
	font-family: $base-font;
	font-size: $base-font-size;
	font-weight: 500;
	letter-spacing: $base-letter-spacing;
	font-feature-settings: "palt";
}

input,select,option,textarea{
	font-family: $base-font;
}

// リンク
a , a *{
	transition-property: color, border-color, background-color, opacity;
	transition-duration: .4s;
	transition-timing-function: ease-in-out;
}
a{
	$color-link: $color-main;
	color: $color-link;
	text-decoration: none;
	// &:visited {
	// 	color: lighten($color-link, 5%);
	// }
	&:hover {
		outline: 0;
		color: darken($color-link, 5%);
		text-decoration: underline;
	}
	&:active {
		outline: 0;
		color: $color-accent;
	}
}

::selection { // css3
	background-color: darken($color-main, 10%);
	color: #fff;
}
