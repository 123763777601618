@use '../global' as *;
@use "sass:math";

$column: 12;
$grid_margin_pc: 64px;
$grid_margin_tab: 40px;
$grid_margin_sp: 24px;
.row{
	margin-left: math.div($grid_margin_pc , -2);
	margin-right: math.div($grid_margin_pc , -2);
	&:after{
		content: "";
		display: block;
		clear: both;
	}
	@include mq(tab){
		margin-left: math.div($grid_margin_tab , -2);
		margin-right: math.div($grid_margin_tab , -2);
	}
	@include mq(sp){
		margin-left: math.div($grid_margin_sp , -2);
		margin-right: math.div($grid_margin_sp , -2);
	}
}
%col{
	box-sizing: border-box;
	float: left;
	padding: math.div($grid_margin_pc , 2);
	:first-child{
		margin-top: 0;
	}
	@include mq(tab){
		padding: math.div($grid_margin_tab , 2);
	}
	@include mq(sp){
		padding: math.div($grid_margin_sp , 2);
	}
}
.col{
	@for $i from 1 through $column {
		&-#{$i}{
			@extend %col;
			$width: percentage(math.div($i , $column));
			width: $width;
		}
		&-tab-#{$i}{
			@extend %col;
		}
		&-sp-#{$i}{
			@extend %col;
		}
	}
	@include mq(tab){
		@for $i from 1 through $column {
			&-tab-#{$i}{
				$width: percentage(math.div($i , $column));
				width: $width;
				@if $i == $column {
					float: none;
				}
			}
		}
	}
	@include mq(sp){
		@for $i from 1 through $column {
			&-sp-#{$i}{
				$width: percentage(math.div($i , $column));
				width: $width;
				@if $i == $column {
					float: none;
				}
			}
		}
	}
}


.fx-row{
	display: flex;
	flex-wrap: wrap;
	margin-left: math.div($grid_margin_pc , -2);
	margin-right: math.div($grid_margin_pc , -2);
	> *{
		float: none;
	}
	@include mq(tab){
		margin-left: math.div($grid_margin_tab , -2);
		margin-right: math.div($grid_margin_tab , -2);
	}
	@include mq(sp){
		margin-left: math.div($grid_margin_sp , -2);
		margin-right: math.div($grid_margin_sp , -2);
	}
}
