
@use '../global' as *;

body{
	overflow: hidden;
}
.page{
	position: relative;
}

// ----------------------------------------------------
// inner
// ----------------------------------------------------
.inner{
	margin: 0 auto;
	max-width: $inner_width;
	@include mq(tab){
		margin: 0 $padding_tab;
	}
	@include mq(sp){
		margin: 0 $padding_sp;
	}
}

// ----------------------------------------------------
// header
// ----------------------------------------------------
.page-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
	background-color: $color-main;
	color: #fff;
	@include mq("sp"){
		padding: 16px;
	}
}
.logo-main{
	font-size: 2.6rem;
	font-family: $font-b;
	@include mq("sp"){
		font-size: 4vw;
		span{
			display: block;
		}
	}
}

.btn-contact{
	> a{
		display: block;
		padding: .3em 2em;
		border-radius: 2em;
		background-color: rgba(#fff, .2);
		color: #fff;
		font-size: 1.8rem;
	}
	@include mq("sp"){
		> a{
			padding: .3em 1em;
			font-size: 1.5rem;
		}
	}
}
// ----------------------------------------------------
// global navigation
// ----------------------------------------------------
.nav-main{
}

.box-list{
	display: flex;
	flex-wrap: wrap;
	border: 1px solid $color-sub;
	&-item{
		display: flex;
		flex-direction: column;
		width: 33.33%;
		padding: 12px;
		border: 2px solid $color-sub;
		&.-col2{
			width: 66.66%;
		}
		&__title{
			font-family: $font-b;
			font-weight: bold;
			font-size: 2rem;
			text-align: center;
		}
		&__body{
			flex-grow: 1;
			margin: 1em 0 0;
			padding: 1em;
			background-color: #fff;
			._image{
				text-align: center;
				img{
					height: 175px;
				}
			}
		}
	}
	@include mq("tab"){
		&-item{
			width: 50%;
			&.-col2{
				width: 100%;
			}
		}
	}
	@include mq("sp"){
		&-item{
			width: 100%;
			padding: 8px;
			&.-col2{
				width: 100%;
			}
			&__title{
				font-size: 1.6rem;
			}
			&__body{
				padding: .5em .5em 1em;
			}
		}
	}
}

// ----------------------------------------------------
// contents layout
// ----------------------------------------------------
.page-content {
}
.p{
	line-height: 1.86;
	& + &{
		margin-top: 1.2em;
	}
}

.faq-item{
	margin: 2em 0 0;
	border: 2px solid $color-main;
	> dt{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1em 2em;
		font-family: $font-b;
		font-size: 2.2rem;
		line-height: 1.2;
		color: $color-main;
		cursor: pointer;
		transition: .3s;
		&::after{
			content: '';
			border-style: solid;
			border-width: 12px 8px 0;
			border-color: $color-main transparent transparent;
			transition: .3s;
		}
		&.is-open{
			background-color: rgba($color-main, .1);
			&::after{
				transform: rotate(180deg);
			}
		}
	}
	> dd{
		padding: 32px 48px;
		border-top: 1px dashed $color-main;
		// background-color: rgba($color-main, .1);
	}
	@include mq("sp"){
		> dt{
			padding: 16px;
			font-size: 1.8rem;
		}
		> dd{
			padding: 16px;
		}
	}
}
.tbl-input{
	margin: 0 auto;
	max-width: 800px;
	tr{
		&:not(:last-child){
			border-bottom: 1px solid $color_line;
		}
	}
	th,td{
		padding: 1em;
	}
	th{
		.req{
			display: block;
			float: right;
			padding: .2em .5em;
			border-radius: .2em;
			background-color: $color-accent;
			color: #fff;
			font-size: .8em;
		}
	}
	td{
		position: relative;
		input[type="text"],input[type="number"],textarea{
			width: 100%;
			&.error{
				border: 1px solid $color-accent;
			}
		}
		label.error{
			position: absolute;
			bottom: calc(100% - 8px);
			left: .5em;
			padding: .1em .5em;
			border-radius: 2px;
			background-color: $color-accent;
			color: #fff;
			font-size: 1.4rem;
			&::after{
				content: '';
				position: absolute;
				top: 100%;
				left: 4em;
				border: 6px solid transparent;
				border-top-color: $color-accent;
			}
		}
		span.error{
			margin: .4em 0 0;
		}
	}
	@include mq("sm"){
		th,td{
			display: block;
		}
	}
}
.dl-box{
	padding: 32px;
	background-color: rgba(#000, .05);
	> dt{
		text-align: center;
		font-size: 2rem;
		font-weight: bold;
	}
	> dd{}
	@include mq("sp"){
		padding: 16px;
		> dt{
			font-size: 1.8rem;
		}
	}
}

.contact-box{
	&__title{
		text-align: center;
	}
	> dl{
		width: 600px;
		max-width: 100%;
		margin: 24px auto 0;
		padding: 1.5em;
		border: 2px solid $color-main;
		> dt{
			padding: .5em 0;
			background-color: $color-main;
			color: #fff;
			font-size: 2rem;
			font-weight: bold;
			text-align: center;
		}
		> dd{
			margin: 1em 0 0;
			text-align: center;
			a{
				display: block;
				width: fit-content;
				margin: 0 auto;
				font-family: $font-b;
				font-size: 4rem;
			}
		}
	}
	@include mq("sp"){
		> dl{
			> dt{
				font-size: 1.7rem;
			}
			> dd{
				font-size: 1.4rem;
				a{
					font-size: 9vw;
				}
			}
		}
	}
}
.contact-card{
	display: flex;
	gap: 24px;
	> dl{
		width: 50%;
		margin: 24px auto 0;
		padding: 1.5em;
		border: 2px solid $color-main;
		background-color: #fff;
		> dt{
			padding: .5em 0;
			background-color: $color-main;
			color: #fff;
			font-size: 2rem;
			font-weight: bold;
			text-align: center;
		}
		> dd{
			margin: 1em 0 0;
			text-align: center;
			a{
				display: block;
				width: fit-content;
				margin: 0 auto;
				font-family: $font-b;
				font-size: 3rem;
			}
		}
	}
	@include mq("sp"){
		display: block;
		> dl{
			width: auto;
			> dt{
				font-size: 1.6rem;
			}
			> dd{
				> a{
					font-size: 2.4rem;
				}
			}
		}
	}
}
// ----------------------------------------------------
// main block
// ----------------------------------------------------
.breadcrumbs{
}

// ----------------------------------------------------
// sub block
// ----------------------------------------------------


// ----------------------------------------------------
// footer
// ----------------------------------------------------
.page-footer{
	margin: 120px 0 0;
	padding: 64px 0;
	background-color: $color-sub;
	.inner{
		display: flex;
		align-items: flex-end;
		gap: 24px;
	}
	@include mq("sp"){
		margin: 96px 0 0;
		padding: 48px 0;
		.inner{
			display: block;
		}
	}
}
.bnr-simulation{
	position: fixed;
	right: 0;
	bottom: 24px;
	writing-mode: vertical-lr;
	> a{
		display: block;
		padding: 44px .5em .5em;
		background: $color-main url(../img/icon.png) no-repeat center 10px / 26px auto;
		color: #fff;
		text-decoration: none;
		transition: padding .4s;
		&:hover{
			padding-right: .8em;
			padding-left: .8em;
		}
	}
	@include mq("sp"){
		bottom: 0;
		writing-mode: horizontal-tb;
		> a{
			padding: .5em .5em .5em 40px;
			background-position: 8px center;
			&:hover{
				padding: .5em .5em .5em 40px;
			}
		}
	}
}
.ft-author{
	display: flex;
	flex-wrap: wrap;
	gap: 16px 24px;
	align-items: center;
	align-self: center;
	> dt{}
	> dd{
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 1.2;
		span{
			padding: 0 1em 0 0;
			font-size: 1.5rem;
			font-weight: normal;
		}
		&._address{
			width: 100%;
			font-size: 1.6rem;
			font-weight: normal;
			line-height: 1.8;
		}
	}
	@include mq("sp"){
		gap: 8px;
		> dt{
			width: calc(20% - 8px);
		}
		> dd{
			width: 80%;
			font-size: 4.2vw;
			span{
				font-size: .8em;
				padding: 0 .5em 0 0;
			}
			&._address{
				font-size: 1.4rem;
				span{
					display: block;
				}
			}
		}
	}
}
.ft-contact-box{
	width: 45%;
	display: flex;
	gap: 16px;
	> dl{
		width: 360px;
		padding: 1em;
		border: 1px solid $color-main;
		background-color: #fff;
		> dt{
			padding: .2em;
			border-radius: .2em;
			background-color: $color-main;
			color: #fff;
			font-size: 1.4rem;
			text-align: center;
		}
		> dd{
			margin: .4em 0 0;
			._tel{
				font-size: 2.6rem;
				font-weight: bold;
			}
			._link{
				font-weight: bold;
				font-size: 1.8rem;
			}
			p{
				font-size: 1.1rem;
			}
		}
	}
	@include mq("sp"){
		width: auto;
		margin: 24px 0 0;
		display: block;
		> dl{
			width: auto;
			margin: 16px 0 0;
			> dd{
				text-align: center;
			}
		}
	}
}

.copyright{
	margin: 64px 0 0;
	text-align: center;
}
