@use '../global' as *;

@each $q, $size in $space-base-size {
	@each $name, $ratio in $space-size-ratio {
		@include mq($q){
			.p-#{$name}{
				padding: $size * $ratio;
			}
			.py-#{$name}{
				padding-top: $size * $ratio;
				padding-bottom: $size * $ratio;
			}
			.px-#{$name}{
				padding-left: $size * $ratio;
				padding-right: $size * $ratio;
			}
			.pt-#{$name}{
				padding-top: $size * $ratio;
			}
			.pr-#{$name}{
				padding-right: $size * $ratio;
			}
			.pl-#{$name}{
				padding-left: $size * $ratio;
			}
			.pb-#{$name}{
				padding-bottom: $size * $ratio;
			}
		}
	}
}
