@use "../global" as *;

.btn{
	display: inline-block;
	padding: .8em 2em;
	width: 450px;
	max-width: 100%;
	color: #fff;
	border: none;
	border-radius: 2em;
	text-align: center;
	background-color: $color-main;
	&.-lg{
		font-size: 1.2em;
	}
	&:hover{
		color: #fff;
		text-decoration: none;
		background-color: lighten($color-main, 10%);
	}
}