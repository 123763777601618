@use 'variables' as *;

// メディアクエリ
@mixin mq($breakpoint) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}


@mixin scrollbars($size: 5px, $foreground-color: $color_main, $background-color: #ddd) {
  // For Google Chrome
  &::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  &::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  @at-root{
	  body {
	    scrollbar-face-color: $foreground-color;
	    scrollbar-track-color: $background-color;
	  }
  }
}
