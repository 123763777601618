@use "../global" as *;

@font-face {
  font-family: 'webicon';
  src:
    url('../fonts/webicon.ttf?qflvyr') format('truetype'),
    url('../fonts/webicon.woff?qflvyr') format('woff'),
    url('../fonts/webicon.svg?qflvyr#webicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'webicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-quotation-left:before {
  content: "\e90b";
}
.icon-quotation-right:before {
  content: "\e90c";
}
.icon-check:before {
  content: "\e900";
}
.icon-right-o:before {
  content: "\e901";
}
.icon-left-o:before {
  content: "\e902";
}
.icon-buss:before {
  content: "\e903";
}
.icon-camera:before {
  content: "\e904";
}
.icon-pin:before {
  content: "\e905";
}
.icon-faq:before {
  content: "\e906";
}
.icon-tel:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-left:before {
  content: "\e90a";
}
.icon-up:before {
  content: "\e90d";
}
.icon-down:before {
  content: "\e90e";
}
.icon-right:before {
  content: "\e909";
}
.icon-plus:before {
  content: "\e92e";
}
.icon-minus:before {
  content: "\e92f";
}
