@use "../global" as *;

.tab-menu{
	display: flex;
	> li{
		position: relative;
		width: 50%;
		padding: 24px 0;
		border: 1px solid $color_font;
		text-align: center;
		font-weight: bold;
		font-size: 2.2rem;
		cursor: pointer;
		&.is-select{
			background-color: $color-key;
			color: #fff;
			&::before{
				content: '';
				position: absolute;
				left: calc(50% - 12px);
				top: 100%;
				border-width: 12px;
				border-style: solid;
				border-color: transparent;
				border-top-color: $color_font
			}
		}
		&:not(:last-child){
			border-right: none;
		}
	}
	@include mq("sp"){
		> li{
			font-size: 1.8rem;
		}
	}
}

.tab-contents{
	position: relative;
	> *{
		transition: visibility .6s, opacity .6s;
		visibility: visible;
		opacity: 1;
		&:not(.is-select){
			visibility: hidden;
			height: 0;
			overflow: hidden;
			opacity: 0;
			position: absolute;
			top: 0;
		}
	}
}