//----------------------------------------------------
//*! Shitaji.CSS v4.3.0 CC0 by Qrac */
//----------------------------------------------------

*,
::before,
::after {
  margin: 0;
  box-sizing: inherit;
  background-repeat: no-repeat;
  font-size: 1em;
  font-family: inherit;
  font-weight: inherit;
}

::before,
::after {
  vertical-align: inherit;
  text-decoration: inherit;
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  overflow-y: scroll;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer,
main,
section,
article,
aside,
nav,
details,
menu,
figure,
figcaption {
  display: block;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

ol,
ul {
  padding: 0;
  list-style: none;
}

dfn {
	font-style: italic;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

mark {
  background-color: #ffff00;
  color: #000000;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

canvas,
audio,
video {
  display: inline-block;
}

img,
svg,
iframe,
canvas,
audio,
video {
  vertical-align: middle;
}

img {
  border-style: none;
}

svg {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

address {
  font-style: normal;
}

textarea {
  overflow: auto;
  resize: vertical;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

summary {
  display: list-item;
}

table {
  border-collapse: collapse;
}

hr {
  height: 0;
  box-sizing: content-box;
  overflow: visible;
}

template {
  display: none;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

::-moz-selection {
  background-color: #b3d4fc;
  color: #000000;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #000000;
  text-shadow: none;
}

[hidden] {
  display: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}