@use '../global' as *;

.h-md{
  font-size: 3rem;
  text-align: center;
  font-family: $font-b;
  font-weight: bold;
  color: $color-main;
  @include mq("tab"){
    font-size: 2.6rem;
  }
  @include mq("sp"){
    font-size: 2.4rem;
  }
}

.h-md2{
  padding: 1em 0;
	border-image-source: linear-gradient(0, $color-main,$color-main);
	border-image-slice: 0 fill;
	border-image-outset: 0 100vw;
  color: #fff;
  font-size: 2.6rem;
  font-weight: bold;
  font-family: $font-b;
  text-align: center;
  &.-lg{
    font-size: 3.2rem;
  }
  &.-key{
	  border-image-source: linear-gradient(0, $color-key,$color-key);
  }
  @include mq("tab"){
    font-size: 2.4rem;
    &.-lg{
      font-size: 2.8rem;
    }
  }
  @include mq("sp"){
    font-size: 2rem;
    &.-lg{
      font-size: 2rem;
    }
  }
}

.h-sm{
  font-size: 2.8rem;
  font-weight: bold;
  font-family: $font-b;
  ._sm{
    font-size: .65em;
  }
  @include mq("tab"){
    font-size: 2.4rem;
  }
  @include mq("sp"){
    font-size: 2rem;
  }
}