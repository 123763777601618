

img {
	max-width: 100%;
	height: auto;
	max-width: 100%;
	height: auto;
	&[src$=".svg"] {
		width: 100%;
	}
}

iframe {
	max-width: 100%;
}