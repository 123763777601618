@use '../global' as *;

@each $q, $size in $space-base-size {
	@each $name, $ratio in $space-size-ratio {
		@include mq($q){
			.m-#{$name}{
				margin: $size * $ratio;
			}
			.my-#{$name}{
				margin-top: $size * $ratio;
				margin-bottom: $size * $ratio;
			}
			.mx-#{$name}{
				margin-left: $size * $ratio;
				margin-right: $size * $ratio;
			}
			.mt-#{$name}{
				margin-top: $size * $ratio;
			}
			.mr-#{$name}{
				margin-right: $size * $ratio;
			}
			.ml-#{$name}{
				margin-left: $size * $ratio;
			}
			.mb-#{$name}{
				margin-bottom: $size * $ratio;
			}
		}
	}
}

// .m{
// 	@if $type == "all"{
// 		@each $name, $size in $size-all {
// 			&-#{$name}{
// 				margin-top: $size;
// 				margin-bottom: $size;
// 			}
// 			&t-#{$name}{
// 				margin-top: $size;
// 			}
// 			&b-#{$name}{
// 				margin-bottom: $size;
// 			}
// 		}
// 	}
// 	@if $type == "tab"{
// 		@each $name, $size in $size-tab {
// 			&-#{$name}{
// 				margin-top: $size;
// 				margin-bottom: $size;
// 			}
// 			&t-#{$name}{
// 				margin-top: $size;
// 			}
// 			&b-#{$name}{
// 				margin-bottom: $size;
// 			}
// 		}
// 	}
// 	@if $type == "sp"{
// 		@each $name, $size in $size-sp {
// 			&-#{$name}{
// 				margin-top: $size;
// 				margin-bottom: $size;
// 			}
// 			&t-#{$name}{
// 				margin-top: $size;
// 			}
// 			&b-#{$name}{
// 				margin-bottom: $size;
// 			}
// 		}
// 	}
// }
