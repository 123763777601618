@use "sass:math";

// box sizing
html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

// $start: 4;
// $end: 50;
// $step: 10;
// $unit: px;
// .w{
// 	@for $i from $start through $end {
// 		&#{$i * $step}{
// 			width: #{$i * $step}px !important;
// 		}
// 	}
// }
$start: 2;
$end: 100;
$step: 2;
.w{
	@for $i from $start through $end {
		&#{$i * $step}p{
			width: percentage( math.div($i * $step , 100)) !important;
		}
	}
}
.mw{
	@for $i from $start through $end {
		&#{$i * $step}p{
			max-width: percentage( math.div($i * $step , 100)) !important;
		}
	}
}
.w{
	@for $i from 3 through 24 {
		&#{$i}em{
			width: #{$i}em !important;
		}
	}
}
