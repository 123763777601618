@use '../global' as *;

.tbl-input{
  tr{
    &:not(:last-child){
      border-bottom: 1px solid #ddd;
    }
  }
  th,td{
    padding: 1em;
  }
  th{
    width: 16em;
    vertical-align: top;
    text-align: left;
  }
  td{
    ._note{
      margin: .4em 0 0;
      font-size: 1.4rem;
      color: #777;
    }
  }
  @include mq("sp"){
    th,td{
      display: block;
    }
    th{
      padding: 1em .5em 0;
      width: auto;
    }
    td{
      padding: .5em .5em 1em;
    }
  }
}
.tbl-res{
  width: 100%;
  th,td{
    padding: 1em;
    border: 1px solid #ddd;
  }
  th{
    text-align: left;
    width: 40%;
  }
  tr{
    &:nth-child(2n){
      th,td{
        background-color: rgba($color-key, .05);
      }
    }
  }
  @include mq("sp"){
    font-size: 1.4rem;
    th,td{
      display: block;
    }
    th{
      margin: .5em 0 0;
      padding: .5em;
      width: auto;
      border-bottom: none;
    }
    td{
      padding: .5em;
    }
  }
}

.tbl-list{
  width: 100%;
  th,td{
    padding: .5em 1em;
    border: 1px solid $color-main;
  }
  th{
    background-color: rgba($color-main, .1);
  }
  @include mq("sp"){
    display: block;
    overflow: auto;
    tbody{
      display: block;
      width: 600px;
    }
    th,td{
      padding: .5em;
      font-size: 1.4rem;
    }
  }
}